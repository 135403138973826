// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------

export default function LoadingCircularScreen({sx, ...other}) {
  return (
    <Box
      sx={{
        py: 25,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        ...sx,
      }}
      {...other}
    >
      <CircularProgress disableShrink />
    </Box>
  );
}

LoadingCircularScreen.propTypes = {
  sx: PropTypes.object,
};
