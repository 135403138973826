import {Suspense, lazy} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const ReportCreatePage = lazy(() => import('src/pages/pentest/report/create'));
const ReportListPage = lazy(() => import('src/pages/pentest/report/list'));
const ReportEditPage = lazy(() => import('src/pages/pentest/report/edit'));
const ReportDetailsPage = lazy(() => import('src/pages/pentest/report/details'));
// ----------------------------------------------------------------------
const VulnerabilityCreatePage = lazy(() => import('src/pages/pentest/vulnerability/create'));
const VulnerabilityListPage = lazy(() => import('src/pages/pentest/vulnerability/list'));
const VulnerabilityEditPage = lazy(() => import('src/pages/pentest/vulnerability/edit'));
const VulnerabilityDetailsPage = lazy(() => import('src/pages/pentest/vulnerability/details'));
// ----------------------------------------------------------------------

export const pentestRoutes = [
  {
    path: 'pentest',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'reports',
        children: [
          {element: <ReportListPage />, index: true},
          {path: 'list', element: <ReportListPage />},
          {path: ':id', element: <ReportDetailsPage />},
          {path: ':id/edit', element: <ReportEditPage />},
          {path: 'create', element: <ReportCreatePage />},
        ],
      },
      {
        path: 'vulnerabilities',
        children: [
          {element: <VulnerabilityListPage />, index: true},
          {path: 'list', element: <VulnerabilityListPage />},
          {path: ':id', element: <VulnerabilityDetailsPage />},
          {path: ':id/edit', element: <VulnerabilityEditPage />},
          {path: 'create', element: <VulnerabilityCreatePage />},
        ],
      },
    ],
  },
];
