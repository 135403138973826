import {lazy} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {GuestGuard} from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
// OTP
const OTPValidatePage = lazy(() => import('src/pages/auth/otp/validate'));
const OTPVerifyPage = lazy(() => import('src/pages/auth/otp/verify'));
// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthClassicLayout>
            <JwtLoginPage />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'otp',
        children: [
          {
            path: 'validate',
            element: (
              <AuthClassicLayout>
                <OTPValidatePage />
              </AuthClassicLayout>
            ),
          },
          {
            path: 'verify',
            element: (
              <AuthClassicLayout>
                <OTPVerifyPage />
              </AuthClassicLayout>
            ),
          },
        ],
      },
      {
        path: 'register',
        element: (
          <AuthClassicLayout title="Manage the job more effectively with Minimal">
            <JwtRegisterPage />
          </AuthClassicLayout>
        ),
      },
    ],
  }
];
