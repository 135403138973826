import {Suspense, lazy} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const CompanyCreatePage = lazy(() => import('src/pages/management/company/create'))
const CompanyListPage = lazy(() => import('src/pages/management/company/list'))
const CompanyEditPage = lazy(() => import('src/pages/management/company/edit'))
// ----------------------------------------------------------------------
const UserCreatePage = lazy(() => import('src/pages/management/user/create'))
const UserListPage = lazy(() => import('src/pages/management/user/list'))
const UserEditPage = lazy(() => import('src/pages/management/user/edit'))
// ----------------------------------------------------------------------
const ClientCreatePage = lazy(() => import('src/pages/management/client/create'))
const ClientListPage = lazy(() => import('src/pages/management/client/list'))
const ClientEditPage = lazy(() => import('src/pages/management/client/edit'))
// ----------------------------------------------------------------------
const ProductCreatePage = lazy(() => import('src/pages/management/product/create'))
const ProductListPage = lazy(() => import('src/pages/management/product/list'))
const ProductEditPage = lazy(() => import('src/pages/management/product/edit'))
// ----------------------------------------------------------------------
const MethodologyCreatePage = lazy(() => import('src/pages/management/methodology/create'))
const MethodologyListPage = lazy(() => import('src/pages/management/methodology/list'))
const MethodologyEditPage = lazy(() => import('src/pages/management/methodology/edit'))
// ----------------------------------------------------------------------
const CWEtListPage = lazy(() => import('src/pages/management/cwe/list'));
// ----------------------------------------------------------------------
const VulnerabilityCategoryListPage = lazy(() => import('src/pages/management/vulnerability-category/list'))
// ----------------------------------------------------------------------
const VulnerabilityTypeListPage = lazy(() => import('src/pages/management/vulnerability-type/list'))
// ----------------------------------------------------------------------
const VulnerabilityTemplateCreatePage = lazy(() => import('src/pages/management/vulnerability-template/create'))
const VulnerabilityTemplateListPage = lazy(() => import('src/pages/management/vulnerability-template/list'))
const VulnerabilityTemplateEditPage = lazy(() => import('src/pages/management/vulnerability-template/edit'))

export const managementRoutes = [
    {
        path: 'management',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            {
                path: 'user',
                children: [
                    {element: <UserListPage />, index: true},
                    {path: 'list', element: <UserListPage />},
                    {path: ':id', element: <UserListPage />},
                    {path: ':id/edit', element: <UserEditPage />},
                    {path: 'create', element: <UserCreatePage />},
                ],
            },
            {
                path: 'company',
                children: [
                    {element: <CompanyListPage />, index: true},
                    {path: 'list', element: <CompanyListPage />},
                    {path: ':id', element: <CompanyListPage />},
                    {path: ':id/edit', element: <CompanyEditPage />},
                    {path: 'create', element: <CompanyCreatePage />},
                ],
            },
            {
                path: 'client',
                children: [
                    {element: <ClientListPage />, index: true},
                    {path: 'list', element: <ClientListPage />},
                    {path: ':id', element: <ClientEditPage />},
                    {path: ':id/edit', element: <ClientEditPage />},
                    {path: 'create', element: <ClientCreatePage />},
                ],
            },
            {
                path: 'product',
                children: [
                    {element: <ProductListPage />, index: true},
                    {path: 'list', element: <ProductListPage />},
                    {path: ':id', element: <ProductEditPage />},
                    {path: ':id/edit', element: <ProductEditPage />},
                    {path: 'create', element: <ProductCreatePage />},
                ],
            },
            {
                path: 'methodology',
                children: [
                    {element: <MethodologyListPage />, index: true},
                    {path: 'list', element: <MethodologyListPage />},
                    {path: ':id', element: <MethodologyEditPage />},
                    {path: ':id/edit', element: <MethodologyEditPage />},
                    {path: 'create', element: <MethodologyCreatePage />},
                ],
            },
            {
                path: 'vulnerability',
                children: [
                    {
                        path: 'template',
                        children: [
                            {element: <VulnerabilityTemplateListPage />, index: true},
                            {path: 'list', element: <VulnerabilityTemplateListPage />},
                            {path: ':id', element: <VulnerabilityTemplateEditPage />},
                            {path: ':id/edit', element: <VulnerabilityTemplateEditPage />},
                            {path: 'create', element: <VulnerabilityTemplateCreatePage />},
                            {path: ':id/clone', element: <VulnerabilityTemplateCreatePage />},
                        ],
                    },
                ],
            },
            {
                path: 'cwe',
                children: [
                    {element: <CWEtListPage />, index: true},
                    {path: 'list', element: <CWEtListPage />},
                    {path: ':id', element: <CWEtListPage />},
                    {path: ':id/edit', element: <CWEtListPage />},
                    {path: 'create', element: <CWEtListPage />},
                ],
            },
            {
                path: 'vulnerability',
                children: [
                    {
                        path: 'category',
                        children: [
                            {element: <VulnerabilityCategoryListPage />, index: true},
                        ],
                    },
                ],
            },
            {
                path: 'vulnerability',
                children: [
                    {
                        path: 'type',
                        children: [
                            {element: <VulnerabilityTypeListPage />, index: true},
                        ],
                    },
                ],
            },
        ],
    },
];
