import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  companies: [],
  company: null,
  companiesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  companyStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // GET LIST
    getCompaniesStart(state) {
      state.companiesStatus.loading = true;
      state.companiesStatus.empty = false;
      state.companiesStatus.error = null;
    },
    getCompaniesFailure(state, action) {
      state.companiesStatus.loading = false;
      state.companiesStatus.empty = false;
      state.companiesStatus.error = action.payload;
    },
    getCompaniesSuccess(state, action) {
      const companies = action.payload;

      state.companies = companies;

      state.companiesStatus.loading = false;
      state.companiesStatus.empty = !companies.length;
      state.companiesStatus.error = null;
    },

    // GET OBJECT
    getCompanyStart(state) {
      state.companyStatus.loading = true;
      state.companyStatus.error = null;
    },
    getCompanyFailure(state, action) {
      state.companyStatus.loading = false;
      state.companyStatus.error = action.payload;
    },
    getCompanySuccess(state, action) {
      const company = action.payload;

      state.company = company;

      state.companyStatus.loading = false;
      state.companyStatus.error = null;
    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCompanies() {
  return async (dispatch) => {
    dispatch(slice.actions.getCompaniesStart());
    try {
      const response = await axios.get(API_ENDPOINTS.company.list);
      dispatch(slice.actions.getCompaniesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getCompaniesFailure(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompany(companyId) {
  return async (dispatch) => {
    dispatch(slice.actions.getCompanyStart());
    try {
      const response = await axios.get(API_ENDPOINTS.company.get, {
        params: {
          companyId,
        },
      });
      dispatch(slice.actions.getCompanySuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getCompanyFailure(error));
    }
  };
}
