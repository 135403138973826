const ignoreWords = ["of", "the", "and"];

export function paramCase(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
}

export function snakeCase(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]/g, '');
}

export function lowerCase(str) {
  return str
    .toLowerCase()
}

export function titleCase(str) {

  const ArrayOfStrings = str.split(" ");
  const ArrayUpperCased = [];

  ArrayOfStrings.map((word) => {
    if (!ignoreWords.includes(word.toLowerCase())) {
      ArrayUpperCased.push(word.charAt(0).toUpperCase() + word.slice(1));
    }

    ArrayUpperCased.push(word.toLowerCase());
    
    // eslint-disable-next-line  no-useless-return
    return;
  })

  return ArrayUpperCased.join(" ");
}

export function capitalizeFirstLetter(sentence) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}
