// ----------------------------------------------------------------------

import {LANG_STORAGE_KEY, TENANT_STORAGE_KEY} from "src/common";
import { defaultLang } from 'src/locales/config-lang';

export function localStorageAvailable() {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
}

export function localStorageGetItem(key, defaultValue = '') {
  const storageAvailable = localStorageAvailable();

  let value;

  if (storageAvailable) {
    value = localStorage.getItem(key) || defaultValue;
  }

  return value;
}

export function localStorageGetLangBrowser() {
  const currentLang = localStorageGetItem(LANG_STORAGE_KEY, defaultLang.value);
  return currentLang === 'ptBR' ? 'pt-BR' : currentLang;
}

export function localStorageGetLang() {
  return localStorageGetItem(LANG_STORAGE_KEY, defaultLang.value);
}

export function sessionStorageGetTenantId() {
  return sessionStorage.getItem(TENANT_STORAGE_KEY);
}

export function sessionStorageGetItem(key, defaultValue = '') {
  return sessionStorage.getItem(key)|| defaultValue;
}
