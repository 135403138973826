import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import kanbanReducer from './slices/kanban';
import calendarReducer from './slices/calendar';

import productReducer from './slices/product';
import reportReducer from './slices/report';
import companyReducer from './slices/company';
import clientReducer from './slices/client';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

const reportPersistConfig = {
  key: 'report',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const companyPersistConfig = {
  key: 'company',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const clientPersistConfig = {
  key: 'client',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  calendar: calendarReducer,
  product: persistReducer(productPersistConfig, productReducer),
  report: persistReducer(reportPersistConfig, reportReducer),
  company: persistReducer(companyPersistConfig, companyReducer),
  client: persistReducer(clientPersistConfig, clientReducer),
});
