import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import {sleep} from 'src/utils/sleep';

// ----------------------------------------------------------------------

const initialState = {
  clients: [],
  client: null,
  clientsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  clientStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // GET LIST
    getClientsStart(state) {
      state.clientsStatus.loading = true;
      state.clientsStatus.empty = false;
      state.clientsStatus.error = null;
    },
    getClientsFailure(state, action) {
      state.clientsStatus.loading = false;
      state.clientsStatus.empty = false;
      state.clientsStatus.error = action.payload;
    },
    getClientsSuccess(state, action) {
      const clients = action.payload;
      state.clients = clients;
      state.clientsStatus.loading = false;
      state.clientsStatus.empty = !clients.length;
      state.clientsStatus.error = null;
    },
    // GET OBJECT
    getClientStart(state) {
      state.clientStatus.loading = true;
      state.clientStatus.error = null;
    },
    getClientSuccess(state, action) {
      const client = action.payload;
      state.client = client;
      state.clientStatus.loading = false;
      state.clientStatus.error = null;
    },
    setStatusFailure(state, action) {      
      state.clientStatus.loading = false;
      state.clientStatus.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createClient(data) {
  return async (dispatch) => {
    dispatch(slice.actions.getClientStart());
    try {
      const response = await axios.post(API_ENDPOINTS.client.create, data)
      dispatch(slice.actions.getClientSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.setStatusFailure(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateClient(clientId, data) {
  return async (dispatch) => {
    dispatch(slice.actions.getClientStart());
    try {
      const response = await axios.post(`${API_ENDPOINTS.client.update}/${clientId}`, data)
      dispatch(slice.actions.getClientSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.setStatusFailure(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClients() {
  return async (dispatch) => {
    dispatch(slice.actions.getClientsStart());
    try {
      await sleep(500);
      const response = await axios.get(API_ENDPOINTS.client.list);
      dispatch(slice.actions.getClientsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getClientsFailure(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClient(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.getClientStart());
    try {
      const response = await axios.get(API_ENDPOINTS.client.details, {
        params: {
          clientId,
        },
      });
      dispatch(slice.actions.getClientSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.setStatusFailure(error));
    }
  };
}
