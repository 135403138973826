import { useCallback } from 'react';
// @mui
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import useTenant from 'src/hooks/use-tenant';

// ----------------------------------------------------------------------

export default function TenantsPopover() {
  const tenant = useTenant();
  const popover = usePopover();

  const handleChangeTenant = useCallback(
    (newTenant) => {
      tenant.onChangeTenant(newTenant);
      popover.onClose();      
    },
    [tenant, popover]
  );

  return (
    <>
      <Button
        variant="soft"
        size="medium"
        color={popover.open ? 'primary' : 'inherit'}
        onClick={popover.onOpen}
        startIcon={<Iconify icon="mdi:company" width={24} sx={{ color: popover.open ? 'primary' : 'action.active'}}/>}
      >
      {tenant.currentTenant?.name}
      </Button>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {tenant.tenantsUser?.map((option) => (
          <MenuItem
            key={option.id}
            selected={option.id === tenant.currentTenantId}
            onClick={() => handleChangeTenant(option.id)}
          >
            <Iconify icon="mdi:company" sx={{ borderRadius: 0.65, width: 24 }} />

            {option.name}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
