export const STORAGE_KEY = 'accessToken';
export const TENANT_STORAGE_KEY = 'tenantId';
export const LANG_STORAGE_KEY = 'i18nextLng';

export const TENANT_HEADER_KEY = 'x-tenant-id';
export const LANGUAGE_HEADER_KEY = 'Accept-Language';

export const ACTIVE_OPTIONS = [
    {value: true, label: 'True'},
    {value: false, label: 'False'},
];

export const VULNERABILITY_SEVERITY_OPTIONS = [
    {value: 0, label: 'None'},
    {value: 1, label: 'Info'},
    {value: 2, label: 'Low'},
    {value: 3, label: 'Medium'},
    {value: 4, label: 'High'},
    {value: 5, label: 'Critical'},
];

export const VULNERABILITY_STATUS_OPTIONS = [
    {value: 1, label: 'Open'},
    {value: 0, label: 'Closed'},
];