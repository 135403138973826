// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  PENTEST: '/pentest',
  MANAGEMENT: '/management',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  cweMitre:'https://cwe.mitre.org/data/definitions/',
  erros: {
    error_500:'/500',
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
    },
    otp: {
      generate: `${ROOTS.AUTH}/otp/generate`,
      verify: `${ROOTS.AUTH}/otp/verify`,
      validate: `${ROOTS.AUTH}/otp/validate`,
      disable: `${ROOTS.AUTH}/otp/disable`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
  // PENTEST
  pentest: {
    root: ROOTS.PENTEST,
    report: {
      root: `${ROOTS.PENTEST}/reports`,
      create: `${ROOTS.PENTEST}/reports/create`,
      details:  (id) => `${ROOTS.PENTEST}/reports/${id}`,
      edit:  (id) => `${ROOTS.PENTEST}/reports/${id}/edit`,
    },
    vulnerability: {
      root: `${ROOTS.PENTEST}/vulnerabilities`,
      create: `${ROOTS.PENTEST}/vulnerabilities/create`,
      details:  (id) => `${ROOTS.PENTEST}/vulnerabilities/${id}`,
      edit:  (id) => `${ROOTS.PENTEST}/vulnerabilities/${id}/edit`,
    },
  },
  // MANAGEMENT
  management: {
    root: ROOTS.MANAGEMENT,
    user: {
      root: `${ROOTS.MANAGEMENT}/user`,
      create: `${ROOTS.MANAGEMENT}/user/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/user/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/user/${id}/edit`,
    },
    company: {
      root: `${ROOTS.MANAGEMENT}/company`,
      create: `${ROOTS.MANAGEMENT}/company/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/company/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/company/${id}/edit`,
    },
    client: {
      root: `${ROOTS.MANAGEMENT}/client`,
      create: `${ROOTS.MANAGEMENT}/client/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/client/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/client/${id}/edit`,
    },
    product: {
      root: `${ROOTS.MANAGEMENT}/product`,
      create: `${ROOTS.MANAGEMENT}/product/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/product/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/product/${id}/edit`,
    },
    methodology: {
      root: `${ROOTS.MANAGEMENT}/methodology`,
      create: `${ROOTS.MANAGEMENT}/methodology/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/methodology/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/methodology/${id}/edit`,
    },
    cwe: {
      root: `${ROOTS.MANAGEMENT}/cwe`,
      create: `${ROOTS.MANAGEMENT}/cwe/create`,
      details: `${ROOTS.MANAGEMENT}/cwe/:id`,
      edit: `${ROOTS.MANAGEMENT}/cwe/:id/edit`,
    },
    vulnerability: {
      root: `${ROOTS.MANAGEMENT}/vulnerability`,
      category: `${ROOTS.MANAGEMENT}/vulnerability/category`,
      type: `${ROOTS.MANAGEMENT}/vulnerability/type`,
      template: `${ROOTS.MANAGEMENT}/vulnerability/template`,
    },
    vulnerabilityCategory: {
      root: `${ROOTS.MANAGEMENT}/vulnerability/category`,
      create: `${ROOTS.MANAGEMENT}/vulnerability/category/create`,
      details: `${ROOTS.MANAGEMENT}/vulnerability/category/:id`,
      edit: `${ROOTS.MANAGEMENT}/vulnerability/category/:id/edit`,
    },
    vulnerabilityType: {
      root: `${ROOTS.MANAGEMENT}/vulnerability/type`,
      create: `${ROOTS.MANAGEMENT}/vulnerability/type/create`,
      details: `${ROOTS.MANAGEMENT}/vulnerability/type/:id`,
      edit: `${ROOTS.MANAGEMENT}/vulnerability/type/:id/edit`,
    },
    vulnerabilityTemplate: {
      root: `${ROOTS.MANAGEMENT}/vulnerability/template`,
      create: `${ROOTS.MANAGEMENT}/vulnerability/template/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/vulnerability/template/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/vulnerability/template/${id}/edit`,
      clone: (id) => `${ROOTS.MANAGEMENT}/vulnerability/template/${id}/clone`,
    },
  }
};
