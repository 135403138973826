import {useCallback} from 'react';
import {find} from 'lodash';
// auth
import {useAuthContext} from 'src/auth/hooks';
// routes
import {useRouter} from 'src/routes/hook';
// utils
import {TENANT_STORAGE_KEY} from 'src/common';
import {sessionStorageGetTenantId} from 'src/utils/storage-available';


export default function useTenant() {
    const router = useRouter();
    const {user} = useAuthContext();

    const tenantIdStorage = sessionStorageGetTenantId();
    const tenantsUser = getTenants()

    const currentTenantId = tenantIdStorage || getDefaultTenantId();
    const currentTenant = find(tenantsUser, ['id', currentTenantId]) || getDefaultTenant();

    const onChangeTenant = useCallback(
        (newTenant) => {
            if (newTenant && newTenant !== currentTenantId) {
                sessionStorage.setItem(TENANT_STORAGE_KEY, newTenant);
                router.reload();
            }
        },
        [currentTenantId, router]
    );

    function getDefaultTenantId() {
        if (!isTenantsValid()) {
            return null;
        }

        return user?.tenants[0]?.id;
    }

    function getDefaultTenant() {
        if (!isTenantsValid()) {
            return '';
        }
        return user?.tenants[0]?.name;
    }

    function getTenants() {
        if (!isTenantsValid()) {
            return [];
        }
        return user?.tenants || [];
    }

    function isTenantsValid(){
        return (user && user.tenants)
    }

    return {
        currentTenant,
        currentTenantId,
        tenantsUser,
        onChangeTenant
    };
}