import {isEmpty} from 'lodash';
import md5 from 'md5'

export function getUrlAvatar(email) {
    const baseUrl = 'https://www.gravatar.com/avatar/'
    const query = 's=100'

    if (isEmpty(email)) {
        return '';
    }

    // Gravatar service currently trims and lowercases all registered emails
    const formattedEmail = (`${email}`).trim().toLowerCase();

    const hashEmail = md5(formattedEmail, {encoding: "binary"});

    return `${baseUrl}${hashEmail}?${query}`;
}