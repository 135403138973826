import {useMemo} from 'react';
// routes
import {paths} from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
// locales
import { useLocales } from 'src/locales';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  bug_duotone: icon('ic_bug_bold_duotone'),
  category_duotone: icon('ic_category_duotone'),
  layers_duotone: icon('ic_layers_duotone'),
  copy_duotone: icon('ic_copy_duotone'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard},
        ],
      },
      // PENTEST
      // ----------------------------------------------------------------------
      {
        subheader: t('pentest'),
        items: [
          {
            title: t('reports'),
            path: paths.pentest.report.root,
            icon: ICONS.file,
            children: [
              {title: t('list'), path: paths.pentest.report.root},
              {title: t('create'), path: paths.pentest.report.create},
            ],
          },
          {
            title: t('vulnerabilities'),
            path: paths.pentest.vulnerability.root,
            icon: ICONS.bug_duotone,
            children: [
              {title: t('list'), path: paths.pentest.vulnerability.root},
              {title: t('create'), path: paths.pentest.vulnerability.create},
            ],
          },
        ],
      },
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          {
            title: t('user'),
            path: paths.management.user.root,
            icon: ICONS.user,
            children: [
              {title: t('list'), path: paths.management.user.root},
              {title: t('create'), path: paths.management.user.create},
            ],
          },
          {
            title: t('company'),
            path: paths.management.company.root,
            icon: ICONS.banking,
            children: [
              {title: t('list'), path: paths.management.company.root},
              {title: t('create'), path: paths.management.company.create},
            ],
          },
          {
            title: t('client'),
            path: paths.management.client.root,
            icon: ICONS.job,
            children: [
              {title: t('list'), path: paths.management.client.root},
              {title: t('create'), path: paths.management.client.create},
            ],
          },
          {
            title: t('product'), 
            path: paths.management.product.root, 
            icon: ICONS.product,
            children: [
              {title: t('list'), path: paths.management.product.root},
              {title: t('create'), path: paths.management.product.create},
            ],
          },
          {
            title: t('methodology'), 
            path: paths.management.methodology.root, 
            icon: ICONS.tour,
            children: [
              {title: t('list'), path: paths.management.methodology.root},
              {title: t('create'), path: paths.management.methodology.create},
            ],
          },
          {
            title: t('vulnerability_templates'),
            path: paths.management.vulnerabilityTemplate.root,
            icon: ICONS.copy_duotone,
            children: [
              {title: t('list'), path: paths.management.vulnerabilityTemplate.root},
              {title: t('create'), path: paths.management.vulnerabilityTemplate.create},
            ],
          },
          {
            title: t('vulnerability_categories'),
            path: paths.management.vulnerabilityCategory.root,
            icon: ICONS.category_duotone,
            caption: t('vulnerability_categories_description'),
          },
          {
            title: t('vulnerability_types'),
            path: paths.management.vulnerabilityType.root,
            icon: ICONS.layers_duotone,
            caption: t('vulnerability_types_description')
          },
          {
            title: t('cwe'),
            path: paths.management.cwe.root,
            icon: ICONS.blog,
            caption: t('common_weakness_enumeration'),
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
