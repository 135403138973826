import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  reports: [],
  report: null,
  reportsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  reportStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // GET REPORTS
    getReportsStart(state) {
      state.reportsStatus.loading = true;
      state.reportsStatus.empty = false;
      state.reportsStatus.error = null;
    },
    getReportsFailure(state, action) {
      state.reportsStatus.loading = false;
      state.reportsStatus.empty = false;
      state.reportsStatus.error = action.payload;
    },
    getReportsSuccess(state, action) {
      const reports = action.payload;

      state.reports = reports;

      state.reportsStatus.loading = false;
      state.reportsStatus.empty = !reports.length;
      state.reportsStatus.error = null;
    },

    // GET PRODUCT
    getReportStart(state) {
      state.reportStatus.loading = true;
      state.reportStatus.error = null;
    },
    getReportFailure(state, action) {
      state.reportStatus.loading = false;
      state.reportStatus.error = action.payload;
    },
    getReportSuccess(state, action) {
      const report = action.payload;

      state.report = report;

      state.reportStatus.loading = false;
      state.reportStatus.error = null;
    },

  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReports() {
  return async (dispatch) => {
    dispatch(slice.actions.getReportsStart());
    try {
      const response = await axios.get(API_ENDPOINTS.report.list);
      dispatch(slice.actions.getReportsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getReportsFailure(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getReport(reportId) {
  return async (dispatch) => {
    dispatch(slice.actions.getReportStart());
    try {
      const response = await axios.get(API_ENDPOINTS.report.details, {
        params: {
          reportId,
        },
      });
      dispatch(slice.actions.getReportSuccess(response.data.report));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getReportFailure(error));
    }
  };
}
